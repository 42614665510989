import React from "react";
import ScrollableAnchor from "react-scrollable-anchor";
//import axios from "axios";
import "./_intro.scss";
import calcPos from "./Calc.js";
import data from "./dataset/intro.json";

class Intro extends React.Component {
  constructor() {
    super();

    this.count = 0;
    this.state = {
      data: data,
      isLoading: false
    };
  }

  // componentWillMount() {
  //   // app.use(function(req, res, next) {
  //   //   res.header("Access-Control-Allow-Origin", "*");
  //   //   res.header("Access-Control-Allow-Headers", "X-Requested-With");
  //   //   next();
  //   //   });

  //   axios

  //     //online http://philipphafellner.com/show/content/items/homeItems.json.js
  //     //https://cors-anywhere.herokuapp.com/https://infallible-meninsky-3e22da.netlify.com/data/intro.json
  //     .get("https://infallible-meninsky-3e22da.netlify.com/data/intro.json", {
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //         "Content-Type": "application/json"
  //       },
  //       withCredentials: false,
  //       responseType: "json"
  //     })
  //     .then(response => {
  //       this.setState({
  //         data: response.data,
  //         isLoading: false
  //       });
  //     })
  //     .catch(function(error) {
  //       console.log(error);
  //     });
  // }

  render() {
    return (
      <section id="intro">
        <ScrollableAnchor id={"intro"}>
          {!this.state.isLoading ? (
            <React.Fragment>
              <h3>
                <span>{this.state.data.header}</span>
              </h3>
              <div className="description">
                <div>
                  <span>{this.state.data.description.split("%")[0]}</span>
                </div>
                <div className="wrapper">
                  <ul
                    style={{
                      top:
                        calcPos(this.props.scrollPos, 0.0, 0.2, 2, 43.5) *
                        -1 +
                        "vw"
                    }}
                  >
                    {this.state.data.descAttributes.map((tObj, i) => {
                      return (
                        <li key={i}>
                          <span>{tObj}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div>
                  <span>{this.state.data.description.split("%")[1]}</span>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <span>is loading</span>
          )}
        </ScrollableAnchor>
      </section>
    );
  }
}
export default Intro;
