import React from "react";
import ScrollableAnchor from "react-scrollable-anchor";
import "./_skill.scss";
import axios from "axios";

class Skill extends React.Component {
  constructor() {
    super();
    this.state = {
      header: "Skills",
      data: {},
      isLoading: false,
    };
  }

  componentWillMount() {
    /* axios
      .get(
        "https://cors-anywhere.herokuapp.com/https://infallible-meninsky-3e22da.netlify.com/data/skill.json",
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json"
          },
          withCredentials: false,
          responseType: "json"
        }
      )
      .then(response => {
        this.setState({
          data: response.data,
          isLoading: false
        });
      })
      .catch(function(error) {
        console.log(error);
      });

      */
  }

  render() {
    return (
      <section id="skill">
        {!this.state.isLoading ? (
          <ScrollableAnchor id={"skill"}>
            <React.Fragment>
              <div className="hWrapper">
                <h3>
                  <span>{this.state.header}</span>
                </h3>
              </div>
              <ul className="skillList">
                <li>
                  <span>Sidefx Houdini</span>
                </li>
                <li>
                  <span>Python</span>
                </li>
                <li>
                  <span>VEX</span>
                </li>
                <li>
                  <span>Nuke</span>
                </li>
                <li>
                  <span>Git</span>
                </li>
                <li>
                  <span>Fusion</span>
                </li>
                <li>
                  <span>Mantra</span>
                </li>
                <li>
                  <span>Arnold</span>
                </li>
                <li>
                  <span>C#</span>
                </li>
                <li>
                  <span>Unity</span>
                </li>
                <li>
                  <span>Unreal</span>
                </li>
                <li>
                  <span>Substance Painter</span>
                </li>
                <li>
                  <span>Cinema4D</span>
                </li>
                <li>
                  <span>Maya</span>
                </li>
                <li>
                  <span>Java</span>
                </li>
                <li>
                  <span>JavaScript</span>
                </li>
                <li>
                  <span>HTML</span>
                </li>
                <li>
                  <span>SCSS</span>
                </li>
                <li>
                  <span>Nodejs</span>
                </li>
                <li>
                  <span>d3js</span>
                </li>
                <li>
                  <span>OpenRefine</span>
                </li>
                <li>
                  <span>VDMX</span>
                </li>
                <li>
                  <span>MadMapper</span>
                </li>
                <li>
                  <span>AfterEffects</span>
                </li>
                <li>
                  <span>Photoshop</span>
                </li>
                <li>
                  <span>Illustrator</span>
                </li>
                <li>
                  <span>Jira</span>
                </li>
                <li>
                  <span>Asana</span>
                </li>
                <li>
                  <span>Notion</span>
                </li>
                <li>
                  <span>Git</span>
                </li>
                <li>
                  <span>SVN</span>
                </li>
                <li>
                  <span>Confluence</span>
                </li>
                <li>
                  <span>Google Suite</span>
                </li>
              </ul>
            </React.Fragment>
          </ScrollableAnchor>
        ) : (
          <span>is loading</span>
        )}
      </section>
    );
  }
}

export default Skill;
