const dataset = [
  {titleImg: ["reel2"],
    title: "VFX Movie Reel 2024",
    topic: ["reel", "top"],
    cols: 4,
    year: "2024",
    description:
      "",
    list: [
      {
        element: "video",
        link: "https://player.vimeo.com/video/914589623"
      },
      {
        element: "text",
        text: "Check out my Reel of the most recent Movies I worked on. Contact me directly for more information."
      },
      {
        element: "text",
        text: "Megalopolis - Francis Ford Coppola (2024) - Layout"
      },
      {
        element: "text",
        text: "The Crow (2024) - Layout TD"
      },
      {
        element: "text",
        text: "Fallout - Amazon (2023) - Layout TD"
      },
      {
        element: "text",
        text: "Ironheart - Marvel (2023) - Layout TD"
      },
      {
        element: "text",
        text: "The Boys GenV (2023) - Layout"
      },
      {
        element: "text",
        text: "The Hunger Games: The Ballad of Songbirds and Snakes - Lionsgate (2023) - Layout "
      },
      {
        element: "text",
        text: "Black Panther: Wakanda Forever - Marvel (2022) -Layout/CG"
      },
    ]
  },
  
 
  // {titleImg: ["reel1"],
  //   title: "VFX Reel 2021",
  //   topic: ["reel", "top"],
  //   cols: 4,
  //   year: "2021",
  //   description:
  //     "",
  //   list: [
  //     {
  //       element: "video",
  //       link: "https://player.vimeo.com/video/656141114"
  //     },
  //     {
  //       element: "text",
  //       text: "Check out my Reel of the most recent 3D Projects I worked on."
  //     },
  //   ]
  // },
  // {titleImg: ["scifiship2"],
  //   title: "WIP Spaceship Attack",
  //   topic: ["rnd"],
  //   cols: 4,
  //   year: "2021",
  //   description:
  //     "Compilation of various FX elements",
  //   list: [
  //     {
  //       element: "video",
  //       link: "https://player.vimeo.com/video/655970776",
  //       subtitle: "First part of the sequence."
  //     },
  //     {
  //       element: "text",
  //       text: "This is a current scene I am working on in my free time. It covers several FX elements as the booster of the ship, the portal FX, and the decloak effect of the attacking sapceship."
  //     },
  //     {
  //       element: "text",
  //       text: "After this the attack starts including blaster effects, destruction FX and pyro FX which is still work in progress."
  //     },
      
  //     {
  //       element: "img",
  //       img: "scifiship2",
  //       subtitle: "Rendering of booster fx. - Consists of a single static object, light geometry and pyro fx. An additional masked is rendered out for displacement in the post."
  //     },
  //     {
  //       element: "text",
  //       text: "All elements are created within Houdini and the post is done with Nuke. Davinci Resolve for export."
  //     },
  //     {
  //       element: "img",
  //       img: "scifiship3",
  //       subtitle: "Rendering of the portal fx."
  //     },
  //     {
  //       element: "text",
  //       text: "Various pyro simulations are used to advect particle sources. Furthermore position modifiers based on particle attributes added another layer of magical movement. Some post effects for the particles are already applied in the base rendering."
  //     },
  //     {
  //       element: "img",
  //       img: "scifiship4",
  //       subtitle: "Rendering of the blast. Basic geometry rendered in Houdini, and later modified in the post for a fast approach."
  //     },
  //     {
  //       element: "video",
  //       link: "https://player.vimeo.com/video/638444137",
  //       subtitle: "First blockout animation."
  //     },
  //     {
  //       element: "video",
  //       link: "https://player.vimeo.com/video/639329662",
  //       subtitle: "Final Models implemented. Testing of booster fx."
  //     },
  //     {
  //       element: "text",
  //       text: "Models were provided by external sources."
  //     },
  //   ],
  // },
  // {
  //   img: [""],
  //   title: "Ocean Ship",
  //   cols: 4,
  //   description:
  //     "Ocean Ship",
  //   list: [
  //     {
  //       element: "img",
  //       img: "shield1",
  //     }
  //   ],
  // },
  // {
  //   img: [""],
  //   title: "BMU: Werte Umwelt",
  //   cols: 4,
  //   description:
  //     "Bundesministerium für Umwelt Deutschland: Werte Umwelt",
  //   list: [
  //     {
  //       element: "link",
  //       url: "https://werte-umwelt.de/",
  //     }
  //   ],
  // }, 

  

  
  
  {titleImg: ["minerva2"],
  title: "Unreal Engine Short Movie",
  topic: ["game"],
  cols: 4,
  year: "2023",
  description:
    "",
  list: [
    {
      element: "video",
      link: "https://player.vimeo.com/video/777265684"
    },
    {
      element: "text",
      text: "One person challenge to create a shortmovie within the game engine Unreal."
    },
  ]
},
{titleImg: ["mountain_13"],
  title: "3D Mountain Generation System",
  topic: ["rnd", "top"],
  cols: 4,
  year: "2024",
  description:
    "",
  list: [
    {
      element: "text",
      text: "Heightfield System for user-friendly Mountain Generation. It's a setup with a brick system to add various details in the structure of the terrain. All setup and created in Sidefx Houdini."
    },
    {
      element: "img",
      img: "mountain_2",
      size: "medium"
    },
    {
      element: "img",
      img: "mountain_1",
      size: "small"
    },
    {
      element: "img",
      img: "mountain_3",
      size: "small"
    },
    {
      element: "text",
      text: "The basic setup uses generation bricks of nodes for create the base structure, details, streaks, terraces.."
    },
   
    {
      element: "img",
      img: "mountain_5",
      size: "big"
    },
    {
      element: "text",
      text: "Next i created my own upscale node which not only raise the resolution but also adds tiny noises to the heightfield."
    },
    {
      element: "img",
      img: "mountain_6",
      size: "medium"
    },
    {
      element: "text",
      text: "By masking i selected different areas and applied with different displacement maps the small details. Furthermore i also applied color textures onto the geometry which get baked and exported in the end to apply it directly to the shader. A heightmap was exported by mapping the height to 0 to 1 rgb values. At the end this map is the only element which creates the geometry in lops. A normal map gets exported through CHOPs too."
    },
   
    {
      element: "img",
      img: "mountain_7",
      size: "small"
    },
    {
      element: "img",
      img: "mountain_8",
      size: "small"
    },
    {
      element: "img",
      img: "mountain_9",
      size: "small"
    },
    {
      element: "text",
      text: "Tree Generation"
    },
    {
      element: "text",
      text: "Another part was to create a spruce/fir kind of tree. My goal was to create a setup that is useable also in game engines like Unreal, so i had to create it with as less polygons as possibel. Therefore I created with the Houdini Labs tools a single branch element of a fir. Rendered it out and applied it on another tree setup for a full branch. This is the branch/leaf element i applied in the final tree buildup which get saved in a USD file with different model and material variations." 
    },
    {
      element: "img",
      img: "mountain_11",
      size: "small"
    },
    {
      element: "img",
      img: "mountain_12",
      size: "small"
    },
    
    {
      element: "img",
      img: "mountain_10",
      size: "small"
    },
    
    {
      element: "text",
      text: "Smart Variation Mapper"
    },
    {
      element: "text",
      text: "To not only have a random scatter on the heightfield I build a python coded node which reads out all variants of the plugged in model (in this case the tree) and gives the user the ability to use maps or manual defined attributes per variant set to scatter the way they want."
    },
    {
      element: "img",
      img: "mountain_4",
      size: "medium"
    },
  ]
},
  {titleImg: ["stadium1"],
    title: "Procedural Stadium",
    topic: ["tools", "rnd"],
    cols: 4,
    description:
      "Procedural HDA's to create fast and artist friendly stadiums",
    list: [
      {
        element: "img",
        img: "stadium1",
      },
      {
        element: "video",
        link: "https://player.vimeo.com/video/659745813",
      },
      {
        element: "text",
        text: "The goal was to create an artist friendly tool to build fast and efficient stadium. Which means elements must be able to be positioned manually and at the same time time consuming elements like stairs and booleans should be handled automatically."
      },
      {
        element: "text",
        text: "Entries, VIP areas, stairs and text elements can be places manually and used within the system. Other elements like stairs, fences, seats, roof, and ways are created by the system. Even animated Vellum flags are positioned automatically but can be adjusted through HDA controls."
      },
      {
        element: "img",
        img: "stadium2",
        size: "small"
      },
      {
        element: "img",
        img: "stadium3",
        size: "small"
      },
      {
        element: "img",
        img: "stadium4",
        size: "small"
      }
    ],
  },
  
  
  {titleImg: ["archvis1"],
    title: "3D - Architecture-Visulisation",
    topic: ["dataviz"],
    cols: 4,
    description:
      "Archvis",
    list: [
      {
        element: "text",
        text: "For the Humbold Museum we build the complete building in 3D, each floor, rendered them in different layers to give the designer the flexibility to paint out rooms in Adobe Photoshop. We used the same camera + angle to render special rooms and combined them and have more freedom in compositing."
      },
      {
        element: "img",
        img: "archvis1",
      },
      
      {
        element: "img",
        img: "archvis2",
      },
    ],
  },
  {titleImg: ["pyrofx1"],
    title: "Fire, Dust and Explosion",
    topic: ["rnd"],
    cols: 4,
    description:
      "Shader for Pyro Effects",
    list: [
      {
        element: "img",
        img: "pyrofx1",
        subtitle: "Testing of custom pyro shader implementation."
      },
      {
        element: "img",
        img: "pyrofx2",
        subtitle: "Custom shader setup in Mantra."
      },
      {
        element: "text",
        text: "After simulation, various fields were caluclated by using temperature, density and veloctiy. And used in this custom mantra shader."
      },
      {
        element: "text",
        text: "Animation will follow..."
      },
      {
        element: "video",
        link: "https://player.vimeo.com/video/638445094",
      },
      {
        element: "text",
        text: "Dust created with the PyroSparse Solver"
      },
      {
        element: "text",
        text: "Animation is created out of two seperate dust elements. Each of these elements have various emitters."
      }
    ],
  },
  {titleImg: ["splash1"],
    title: "FX Water",
    topic: ["rnd"],
    cols: 4,
    year: "2022",
    description:
      "",
    list: [
      {
        element: "video",
        link: "https://player.vimeo.com/video/733792933"
      },
      {
        element: "text",
        text: "Basic Airfield System to improve water, its' movement, and splash behaviour."
      },
      {
        element: "video",
        link: "https://player.vimeo.com/video/705171703"
      },
      {
        element: "text",
        text: "My attempt to create a believable sea to coast splash. Whitewater setup and custom wetmap setup for fast iteration in post. Software used: Houdini and Nuke. Assets: Megascan."
      },
    ]
  },
  {titleImg: ["des34"],
    wip: true,
    title: "FX Destruction",
    topic: ["rnd"],
    cols: 4,
    year: 2019,
    description: "VFX Destruction Scene Houdini",
    list: [
      {
        element: "text",
        text:
          "Houdini Destruction FX Project. This project was done in connection with Rebelway's 11 Weeks Destruction in Houdini Workshop. In this time I heavily explored scene setup, model setup and preperation, various rbd solver setups, collider setups, Arnold rendering.",
      },
      {
        element: "video",
        link: "https://player.vimeo.com/video/464628944",
      },
      {
        element: "img",
        img: "des33",
        size: "small"
      },
      {
        element: "img",
        img: "des32",
        size: "small"
      },
      {
        element: "img",
        img: "des31",
        size: "small"

      },

      {
        element: "text",
        text:
          "Top: Preperation of the wood/plank model. The wall model, where I used different destruction levels with different glue strengths for a more realistic output.",
      },

      {
        element: "img",
        img: "des4",
      },


      {
        element: "text",
        text:
          "These model types are also connected with each other to give the feeling of a real constructed wall. These blocks are still breakable but with a stringer impulse.",
      },

      {
        element: "img",
        img: "des23",
        size: "small"
      },
      {
        element: "img",
        img: "des24",
        size: "small"
      },
      {
        element: "img",
        img: "des25",
        size: "small"
      },
      {
        element: "img",
        img: "des27",
        size: "small"
      },
      {
        element: "text",
        text:
          "Some examples of tests I did to create the animation",
        style: { "text-align": "center" }
      },

      {
        element: "gif",
        img: "dest1",
        size: "small"
      },
      {
        element: "gif",
        img: "dest2",
        size: "small"
      },
    ],
  },
  
  {titleImg: ["shield1"],
    title: "VFX Energy Shield",
    topic: ["rnd", "top"],
    cols: 4,
    description:
      "Procedural VFX Energy Shield Houdini, Compositing/Post in Fusion",
    list: [

      {
        element: "video",
        link: "https://player.vimeo.com/video/638174123"
      },
      {
        element: "text",
        text:
          "A procedural energy shield, animated and rendered in Houdini/Mantra. Based on editable parameters like Impact Points, Noises, Particle Systems and from Pyro Solver with Particle Systems as source. Created in the Rebelway Workshop. Compositing and post production took place in Fusion.",
      },
      {
        element: "img",
        img: "shield1",
      },
      {
        element: "img",
        img: "shield2",
        size: "small"
      },

      {
        element: "img",
        img: "shield4",
        size: "small"
      },
      {
        element: "img",
        img: "shield7",
        size: "small"
      },
      {
        element: "img",
        img: "shield5",
        size: "small"
      },
      {
        element: "img",
        img: "shield3",

      },
    ],
  },
  {titleImg: ["abstract4"],
    title: "Abstract Concepts - Part Two",
    topic: ["rnd", "tools"],
    cols: 4,
    description:
      "Abstract Concept Element",
    list: [
      
      {
        element: "img",
        img: "abstract4",
      },
      {
        element: "video",
        link: "https://player.vimeo.com/video/639330520",
      }
    ],
  },
  {titleImg: ["abstract1"],
    title: "Abstract Concepts - Part One",
    topic: ["rnd", "tools"],
    cols: 4,
    description:
      "Procedural Setups",
    list: [
      {
        element: "img",
        img: "abstract1",
      },
      {
        element: "img",
        img: "abstract2",
      }
    ],
  },
  // {
  //   img: [""],
  //   title: "Vellum Variations",
  //   cols: 4,
  //   description:
  //     "Vellum Variations",
  //   list: [
  //     {
  //       element: "img",
  //       img: "shield1",
  //     }
  //   ],
  // },
  // {
  //   img: ["wip"],
  //   title: "Vellum Grains",
  //   cols: 4,
  //   description:
  //     "Vellum Grains",
  //   list: [
  //     {
  //       element: "img",
  //       img: "shield1",
  //     }
  //   ],
  // },
  // {
  //   img: ["wip"],
  //   title: "Flying Rockets",
  //   cols: 4,
  //   description:
  //     "Flying Rockets",
  //   list: [

  //   ],
  // },


  {titleImg: ["book6"],
    title: "Data Viz Welt Verstehen",
    topic: ["dataviz", "top", "web"],
    cols: 2,
    description:
      "Data Viz for Print",
    list: [
      {
        element: "text",
        text:
          "For Sapera GmbH and its former CEO Jan Schwochow I created three main stories in this book. Concept, data scraping, data wrangling, visualization, style and text was all part of my work for these pages."
      },
      {
        element: "img",
        img: "book1"
      },
      
      {
        element: "img",
        img: "book2",
        size: "small",
        subtitle: "The mountains were created by a heightfield setup in Houdini"
      },
      {
        element: "img",
        img: "book3",
        size: "small"
      },
      {
        element: "img",
        img: "book4",
        size: "small"
      },
      {
        element: "img",
        img: "book5",
        subtitle: "Chapter: Deaths on the heightest mountains"
      },
      {
        element: "img",
        img: "book6",
        subtitle: "Chapter: Esport Worldwide"
      }
    ],
  },
  {titleImg: ["miz1"],
    title: "Data Visualization MIZ",
    topic: ["dataviz", "print"],
    cols: 2,
    description:
      "Data Visualization MIZ",
    list: [
      {
        element: "text",
        img: "Datavisualization for the German Music Information Centre",
      },
      {
        element: "img",
        img: "miz1",
      }
    ],
  },
  
  {titleImg: ["sprint2"],
    title: "Sprints & Workshop Facilitator",
    topic: ["manager"],
    cols: 4,
    year: "2017-2021",
    description:
      "Certified Design Sprint Workshop Facilitator",
    list: [
      {
        element: "text",
        text:"As a certified design sprint facilitator i planned, facilitated and presented various design sprints. Live as well as remote with Miro for example. In a 3-5 days workshop its possible to evaluate and analyse a need (from a stakeholder for example), collecting ideas, building concepts and test the best by a user testing.",
      },
      {
        element: "text",
        text:"These learned and partly upgraded methods were used by me as a facilitator when working with clients or for internal projects of Sapera GmbH."

      },
      {
        element: "img",
        img: "sprint1",
      }
    ],
  },
  // {
  //   img: ["po1"],
  //   title: "Product Owner",
  //   cols: 4,
  //   description:
  //     "Product Owner",
  //   list: [
  //     {
  //       element: "img",
  //       img: "po1",
  //     }
  //   ],
  // },

  {titleImg: ["sapera16"],
    title: "Technical Product Management sapera.com",
    topic: ["manager", "top"],
    cols: 4,
    year: "2020-2021",
    description:
      "Technical Product Management and certified Product Owner of sapera.com for selling B2B services",
    list: [
      {
        element: "text",
        text:"*Certified Product Owner of a Scrum-based development team to create a digital platform"
      },
      {
        element: "text",
        text:"*Managing a 30+ person interdisciplinary team, with focus on team-building, road-mapping, budgeting, and close communication with stakeholders."
      },
      {
        element: "text",
        text:"*Developing and implementing agile methods into the team (Scrum & Kanban)"
      },
      {
        element: "text",
        text:"*Facilitating design sprints with internal and external team members"
      },
      {
        element: "text",
        text:"*Coordinating between external partners, agencies and freelancers, software/tool providers"
      },
      {
        element: "img",
        img: "sapera1",
      },
      
      // {
      //   element: "img",
      //   img: "sapera2",
      // },
      // {
      //   element: "img",
      //   img: "sapera3",
      // },
      // {
      //   element: "img",
      //   img: "sapera4",
      // },
      // {
      //   element: "img",
      //   img: "sapera5",
      // },
      // {
      //   element: "img",
      //   img: "sapera6",
      // },
      // {
      //   element: "img",
      //   img: "sapera7",
      // },
      // {
      //   element: "img",
      //   img: "sapera8",
      // },
      // {
      //   element: "img",
      //   img: "sapera9",
      // },
      // {
      //   element: "img",
      //   img: "sapera10",
      // },
      // {
      //   element: "img",
      //   img: "sapera11",
      // },
      // {
      //   element: "img",
      //   img: "sapera12",
      // },
      // {
      //   element: "img",
      //   img: "sapera13",
      // },
      // {
      //   element: "img",
      //   img: "sapera14",
      // },
      // {
      //   element: "img",
      //   img: "sapera15",
      // }
    ],
  },
  // {
  //   img: [""],
  //   title: "Python GPS Loader",
  //   cols: 4,
  //   description:
  //     "Python Tools",
  //   list: [
  //     {
  //       element: "img",
  //       img: "shield1",
  //     }
  //   ],
  // },
  {titleImg: ["vj2"],
    title: "VJing",
    topic: ["live", "top", "games"],
    cols: 4,
    description:
      "VJing",
    list: [
      {
        element: "img",
        img: "vj1",
      },
      {
        element: "img",
        img: "vj2",
        size: "small"
      },

      {
        element: "img",
        img: "vj5",
        size: "small"
      },
      {
        element: "video",
        link: "https://player.vimeo.com/video/639263767",
      }
    ],
  },



  {titleImg: ["ar1"],
    title: "Augmented Reality App",
    topic: ["game", "top"],
    cols: 4,
    description:
      "AR Wallpaper App - iOS and Android - for 4x5m wallpaper. Topic Arion Nebular and the way the image was created. Build with Unity, registered for App Store",
    link:
      "https://medium.com/infographics-next/augmented-infographics-c9fe727937ed",

    list: [
      {
        element: "img",
        img: "ar1",
      },
      {
        element: "text",
        text:
          "AR Wallpaper App - iOS and Android - in the size between A4 (for magazine) up to 4x5 meters large wallpapers - . Topics range from art to science (star map of the Orion Nebula with detailed explanation of how the image was created). Animations created with AfterEffects. Build with Unity, C#, registered for App Store",
      },
    ],
  },
  {titleImg: ["pattern1"],
    title: "Houdini Particles Setup for Pattern Generation",
    topic: ["tools"],
    cols: 3,
    rows: 1,
    description:
      "Adapted Houdini Particles Generation for procedural generated patterns",
    list: [
      {
        element: "img",
        img: "pattern1",
      },
      {
        element: "img",
        img: "pattern2",
        size: "small"
      },
      {
        element: "img",
        img: "pattern3",
        size: "small"
      },
    ],
  },
  {titleImg: ["hdaleaf1"],
    title: "Houdini SmartConnect Tool",
    topic: ["tools"],
    cols: 1,
    description: "Houdini Tool for faster wrangling of nodes",
    link:
      "https://medium.com/@hafellner.philipp/smartconnect-a-python-tool-to-improve-your-workflow-in-houdini-a766500f8671",
    list: [
      {
        element: "gif",
        img: "hdasmart1",
      },
    ],
  },

 
  {titleImg: ["olc1"],
    title: "OLC Presentation",
    topic: ["tools", "rnd", "game", "live"],
    cols: 4,
    description:
      "Scraping of Xing, Python Import to Houdini & Export to Cinema4D & Rendering, Presentation on 20x4m Screen",
    list: [
      {
        element: "img",
        img: "olc1",
      }]

  },
  {titleImg: ["nikeAll"],
    title: "Project Nike Commercial",
    topic: ["rnd"],
    cols: 2,
    rows: 1,
    description:
      "Kinect Tracking with Second Red-Camera, Import & Rendering in Houdini",
    list: [
      {
        element: "img",
        img: "nike1",
        size: "small"
      },
      {
        element: "img",
        img: "nike2",
        size: "small"
      },
      {
        element: "img",
        img: "nike3",
        size: "small"
      }]
  },
  // {titleImg: ["postAll"],
  //   title: "Procedural Shaders 1",
  //   topic: ["rnd"],
  //   cols: 2,
  //   rows: 1,
  //   description: "Procedural Houdini Mantra Shader Tests",
  //   list: [
  //     {
  //       element: "img",
  //       img: "postAll",
  //     }]

  // },
  {titleImg: ["leaf1"],
  title: "Leaf Animation",
  topic: ["tools", "rnd"],
  cols: 2,
  description:
    "Vellum Animation, Rendering Mantra Houdini, Post Fusion",
  link: "https://youtu.be/ULl62bsz5tk",
  list: [
    {
      element: "img",
      img: "leaf1",
    },
    {
      element: "text",
      text:
        "Houdini + Fusion Infection Project. Animation was realized with Vellum. Infection algorithm has a custom setup that was then reused as HDA. The setup is based on a noise layer with different parameters and a paint layer that can be edited.",
    },
    {
      element: "img",
      img: "leaf6",
      size: "small"
    },
    {
      element: "img",
      img: "leaf5",
      size: "small"
    },
    {
      element: "img",
      img: "leaf4",
      size: "small"
    },
    {
      element: "text",
      text:
        "One or more points can be selected as the starting point from which the infection continues. Multiplier and a near neighbor algorithm searches for the next points to be infected. An additional paint layer, which can be activated directly with a button in the Houdini paint tool, can modify the spread of the infection in places to make it appear faster or slower. This should enable an artistfriendly setup",
    },
    {
      element: "img",
      img: "leaf3",
      size: "small"
    },
    {
      element: "img",
      img: "leaf2",
      size: "small"
    },
  ],
},
  {titleImg: ["path1"],
    title: "Big Data - GPS Path Converter & Houdini Conversion",
    topic: ["rnd", "tools", "top"],
    cols: 4,
    description:
      "GPS Dataset Converter - Nodejs, with Python Import to Houdini & Export to Cinema4D & Rendering, Presentation on 20x4m Screen",
    link:
      "https://medium.com/@hafellner.philipp/from-large-gps-datasets-to-3d-animations-eed1508297e3",
    list: [
      {
        element: "img",
        img: "path1",
      },
      {
        element: "img",
        img: "path2",
        size: "small"
      },
      {
        element: "img",
        img: "path3",
        size: "small"
      },
      {
        element: "img",
        img: "path4",
        size: "small"
      },
      {
        element: "img",
        img: "path5",
        size: "small"
      },
      {
        element: "img",
        img: "path6",
        size: "small"
      },
    ],
  },


  {titleImg: ["ludum1"],
    title: "Ludum Dare 46",
    topic: ["game"],
    cols: 2,
    description: "Ludum Dare Gamejam 46, Team of 5",
    list: [

      {
        element: "img",
        img: "ludum1",
        size: "small"
      },

      {
        element: "img",
        img: "ludum4",
        size: "small"
      },
      {
        element: "text",
        text:
          "Game created within a 3 day Ludum Dare Game Jam by an interdisciplinary team in Unity. My focus was on the various reatime kitchen effects and the materials creation. The fx were partly created within Houdini and its Gamedev tools. Materials were created with Substance Painter. The approach was to be efficient as well as trying to align with our visual styleguide.",
      },

      {
        element: "img",
        img: "ludum2",
      },

    ],
  },
  {titleImg: ["ggj201"],
    title: "Global Gamejam 2020",
    topic: ["game"],
    cols: 2,
    description: "Global Gamejam, Team of 3",
    list: [
      {
        element: "img",
        img: "ggj201",
        size: "small"
      },
      {
        element: "img",
        img: "ggj202",
        size: "small"
      },
      {
        element: "text",
        text: "Procedural Island creator done in Houdini. Heightfield Setup."
      },
    ],
  },
  {titleImg: ["curve1"],
    title: "HDA Tools",
    topic: ["tools"],
    cols: 2,
    description:
      "Houdini Plugins for faster Asset creation - VEX, Python",
    link: "https://github.com/PhilHaf/hipCollection",
    list: [
      {
        element: "img",
        img: "curve1",
      }]
  },
  {titleImg: ["proc4"],
    title: "Particle Setup",
    topic: ["tools", "rnd"],
    cols: 2,
    description:
      "Houdini Particle Setup with Leaders and Followers Algorithm",
    list: [
      {
        element: "img",
        img: "proc4",
      }]
  },


  {titleImg: ["bar1"],
    title: "Automated 3D Chart Creation in C4D",
    topic: ["tools", "rnd"],
    cols: 2,
    description:
      "Procedural Barchart - Python Import & Rendering to Houdini",
    list: [
      {
        element: "img",
        img: "bar1",
      },
      {
        element: "text",
        text: "This project was a test to visualize a data set in a 3D software in an automated way. The setup was recreated in Sidefx Houdini as well as in Cinema 4D with Python scripting.",
      }
    ],
  },


  {titleImg: ["redfuji1"],
    title: "AR App for Kids",
    topic: ["game", "tools"],
    cols: 2,
    description:
      "Unity - Augmented Reality Prototype for visual storytelling with children as a target group.",
    link:
      "https://medium.com/infographics-next/augmented-infographics-c9fe727937ed",
    list: [
      {
        element: "img",
        img: "redfuji1",
      },
      {
        element: "video",
        link: "https://www.youtube.com/embed/NRqpZa0vLoo",
      }
    ]
  },
  {titleImg: ["data2"],
    title: "Automated Data Visualization Software for Journalists",
    topic: ["tools", "web", "top", "print"],
    cols: 1,
    description:
      "Imports dataset from Statistic Austria/Excel Files and creates a choropleth map. The output format is an SVG, a pdf file or an interactive HTML container. The used framework is Laravel (PHP)",
    list: [
      {
        element: "img",
        img: "data2",
      }],

  },
  // {
  //   img: ["god1"],
  //   title: "Fallen God",
  //   cols: 2,
  //   description:
  //     "Game made in Unity - 2D Hack and Slay, Focused on Effects, Level Design, Development, Shading",
  //   list: [
  //     {
  //       element: "img",
  //       img: "god1",
  //     },
  //     {
  //       element: "img",
  //       img: "god2",
  //     },
  //     {
  //       element: "img",
  //       img: "god3",
  //     },
  //     {
  //       element: "img",
  //       img: "god4",
  //     },
  //     {
  //       element: "img",
  //       img: "god5",
  //     },
  //     {
  //       element: "img",
  //       img: "god6",
  //     },
  //   ],
  // },

  {titleImg: ["malo0"],
    preimg: "malo1",
    title: "Malofiej Dataviz Workshop",
    topic: ["web"],
    cols: 1,
    description: "Scraped Twitter Dataset of all Donald Trump Tweets - Analyzed in an Article with Infographics",
    list: [
      {
        element: "img",
        img: "malo0",
      }
    ]
  },
  {titleImg: ["sport1"],
    title: "Sport Event Tools",
    topic: ["web"],
    cols: 1,
    description:
      "Modular Sport Visualizations for Web Platforms. - Formula 1, Olympics, Championsleague, Europalueague, Bundesliga",
    link: "http://visual.apa.at/soccer/showcase.bl.html",
    list: [
      {
        element: "img",
        img: "sport1",
        size: "small"
      },
      {
        element: "img",
        img: "sport_2",
        size: "small"
      },
      {
        element: "img",
        img: "sport_3",
      }
    ]
  },

  {titleImg: ["parlamentAll"],
    title: "Austrian Election Visualizations",
    topic: ["web"],
    cols: 1,
    description:
      "Interactive Visualizations with Live Data Stream for TV Shows and Newspapers in Austria.",
    link: "http://visual.apa.at/election/showcase.national.html",
    list: [
      {
        element: "img",
        img: "parlament2_3",
        size: "small"
      },
      {
        element: "img",
        img: "parlament2_4",
        size: "small"
      }

    ]
  },
  {titleImg: ["esc_0"],
    title: "Webpage for Eurovision Song Contest",
    topic: ["web"],
    cols: 2,
    description:
      "Presentation of the Eurovision Song Contest. An Interactive tool to get information about the location, previous winners and the participants",
    list: [
      {
        element: "img",
        img: "esc_0",
      },
      {
        element: "img",
        img: "esc_1",
      }
    ]
  },
  {titleImg: ["bildung1"],
    title: "Bildungsbericht Germany",
    topic: ["web", "print"],
    cols: 2,
    description:
      "Bildungsbericht Germany",
    list: [

      {
        element: "video",
        link: "https://www.youtube.com/embed/ULJTOdyF12k"
      },
      {
        element: "img",
        img: "bildung1"
      },

      {
        element: "img",
        img: "bildung2",
        size: "small"
      },
      {
        element: "img",
        img: "bildung3",
        size: "small"
      },
      {
        element: "img",
        img: "bildung4",
        size: "small"
      }
    ],
  },
];

export default dataset;