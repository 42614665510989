import React from "react";
import "./_projects.scss";
import axios from "axios";
import "./_project.scss";
import { Link } from "react-router-dom";
import dataset from "./dataset";
import Impressum from "./Impressum";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { style } from "d3-selection";

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      data: {},
      isLoading: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      id: this.props.keyId
    });
  }

  // componentWillMount() {

  //     axios
  //     .get("https://cors-anywhere.herokuapp.com/https://infallible-meninsky-3e22da.netlify.com/data/shownProjects.json", {
  //     headers: {
  //         'Access-Control-Allow-Origin': '*',
  //         'Content-Type': 'application/json',
  //     },
  //     withCredentials: false,
  //     responseType: 'json',
  //     }).then(response => {
  //         console.log(response);
  //         this.setState({
  //             data: response.data,
  //             isLoading: false
  //     });
  //     })
  //         .catch(function(error) {
  //         console.log(error);
  //     });
  // }
  //https://www.mediaevent.de/xhtml/srcset.html
  render() {
    return (
      <div className="project">
        {!this.state.isLoading ? (
          <React.Fragment>
            <nav>
              <Link to={`/#projects`}>{String("Home").toUpperCase()}</Link>
            </nav>
            <div className="projectTitle">
              <h2>{dataset[this.props.match.params.id].title.toUpperCase()}</h2>
              <p className="year">{dataset[this.props.match.params.id].year}</p>
              <p>{dataset[this.props.match.params.id].description}</p>
            </div>
            <section class="container">
              {dataset[this.props.match.params.id].list.map((ele, i) => {
                if (ele.element === "img") {

                  return (<div class={"element image " + ele.size}>
                    <Zoom
                      zoomMargin={30}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/dataset/projects/" + ele.img + ".jpg"}
                        alt={dataset[this.props.match.params.id].title}
                        style={{ width: "100%" }}
                      ></img>
                    </Zoom>
                    <p class="subtitle">{ele.subtitle}</p>
                  </div>)
                } else if (ele.element === "gif") {
                  return (<div class={"element image " + ele.size}>
                    <img
                      src={process.env.PUBLIC_URL + "/dataset/projects/" + ele.img + ".gif"}
                      alt={dataset[this.props.match.params.id].title}
                    ></img>
                    <p class="subtitle">{ele.subtitle}</p>
                  </div>)

                } else if (ele.element === "video") {

                  return (<div class="element video">
                    <iframe src={ele.link + "?autoplay=1&loop=1&autopause=0"} frameborder="0" allow="autoplay; fullscreen; picture-in-picture; allowfullscreen" allowfullscreen></iframe>
                    <p class="subtitle">{ele.subtitle}</p>
                  </div>)

                } else {
                  let tText = <div><span>{ele.text}</span></div>

                  return (<div class="element text" style={ele.style}>{tText}</div>)

                }
              }
              )}
            </section>

          </React.Fragment>
        ) : (
          <span>is loading</span>
        )
        }
        <Impressum></Impressum>
      </div >

    );
  }
}

export default Project;
