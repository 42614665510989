import React from "react";
import ScrollableAnchor from "react-scrollable-anchor";
import "./_impressum.scss";
//import axios from "axios";
import data from "./dataset/impressum.json";

class Impressum extends React.Component {
  constructor() {
    super();
    this.state = {
      data: data,
      isLoading: false
    };
  }
  //https://cors-anywhere.herokuapp.com/https://infallible-meninsky-3e22da.netlify.com/data/impressum.json
  // componentWillMount() {
  //   axios
  //     .get(
  //       "https://infallible-meninsky-3e22da.netlify.com/data/impressum.json",
  //       {
  //         headers: {
  //           "Access-Control-Allow-Origin": "*",
  //           "Content-Type": "application/json"
  //         },
  //         withCredentials: false,
  //         responseType: "json"
  //       }
  //     )
  //     .then(response => {
  //       this.setState({
  //         data: response.data,
  //         isLoading: false
  //       });
  //     })
  //     .catch(function(error) {
  //       console.log(error);
  //     });
  //}

  render() {
    return (
      <section id="impressum">
        {!this.state.isLoading ? (
          <ScrollableAnchor id={"impressum"}>
            <React.Fragment>
              <span>{String(this.state.data.left)}</span>
              <span>{String(this.state.data.center)}</span>
              <span>{String(2024)}</span>
            </React.Fragment>
          </ScrollableAnchor>
        ) : (
          <span>is loading</span>
        )}
      </section>
    );
  }
}

export default Impressum;
