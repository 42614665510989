import React from "react";
import ScrollableAnchor from "react-scrollable-anchor";
//import axios from "axios";
import "./_download.scss";
import data from "./dataset/download.json";

class Download extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      data: data
    };
  }

  render() {
    return (
      <section id="download">
        <ScrollableAnchor id={"download"}>
          {!this.state.isLoading ? (
            <React.Fragment>
              <div className="hWrapper">
                <h3>
                  <span>{this.state.data.header}</span>
                </h3>
              </div>
              <div className="download">
                <ul>
                  {this.state.data.links.map((tObj, i) => {
                    return (
                      <li key={i}>
                        <a href={process.env.PUBLIC_URL + "dataset/downloads/" + tObj.element} download>{tObj.header}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </React.Fragment>
          ) : (
            <span>is loading</span>
          )}
        </ScrollableAnchor>
      </section>
    );
  }
}

export default Download;
