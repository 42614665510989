import React from "react";
import ScrollableAnchor from "react-scrollable-anchor";
import "./_projects.scss";
// import axios from "axios";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Link } from "react-router-dom";
import dataset from "./dataset";
import filterset from "./filterset";


class Projects extends React.Component {

  constructor() {
    super();

    this.state = {

      open: false,
      displayId: 0,
      filter: [],
      data:[],
      header: "Projects",
      isLoading: true,
      isHidden: true,
      showAllButton: true,
    };
  }

  handleOpen = (e) => {
    e.preventDefault();

    this.setState({

      displayId: e.target.id,
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  showAlert(e) {
    e.preventDefault();
    alert("Im an alert");
  }

  showMore = () => {

    this.setState({
      showAllButton: false,
      isHidden: false,
    });
  };

  addToFilter = (e) => {

    const key = e;

    const newFilter = this.state.filter;
    const checkedCount = newFilter.filter(item => item.checked==true).length;

  if (checkedCount == newFilter.length) {
    
    newFilter.map(item => {
      if (item.key != key) {

        
              item.checked = false;
      }
      return item;
    });
  } else {

    newFilter.map(item => {
      if (item.key === key) {

        if (item.checked==true) {
          
          item.checked = false;
          return;
        }
        else {

          item.checked = true;
          return;
        }        
      }
      return item;
    });
  }

  const newcheckedCount = newFilter.filter(item => item.checked==true).length;

  if (newcheckedCount==0){

    newFilter.map(item => {
 
      item.checked = true;
      return item;
    });
  }

    const filteredData = this.state.origData.filter(item => {
      return newFilter.some(filter => {
            if (filter.checked==true) {

              return item.topic.includes(filter.key);
            }
            
          })
      
      });

      var showAllButton = true;
      var isHidden =  true;

      if (filteredData.length < 7) {

        showAllButton = false;
        isHidden =  false;
      }

    this.setState({
      filter:newFilter,
      data: filteredData,
      showAllButton: showAllButton,
      isHidden: isHidden,
    });
  }

  componentDidMount() {
    const tData = dataset.map((t,i) => ({...t, id: i}))

    this.setState({

      filter: filterset.map(t => ({...t, checked: true})),
      origData: dataset.map((t,i) => ({...t, id: i})),
      data: dataset.map((t,i) => ({...t, id: i}))
    });
  }

  componentWillMount() {
    /*
    axios
      .get(
        "https://cors-anywhere.herokuapp.com/https://infallible-meninsky-3e22da.netlify.com/data/shownProjects.json",
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json"
          },
          withCredentials: false,
          responseType: "json"
        }
      )
      .then(response => {
        
        this.setState({
          data: response.data,
          isLoading: false
        });
        
        
      })
      .catch(function(error) {
        console.log(error);
      });
      */
  }

  render() {
    return (
      <section id="projects">
        

       
        <ScrollableAnchor id={"projects"}>
          <div>
          <React.Fragment>
            <div className="hWrapper">
              <h3>
                <span>{this.state.header}</span>
              </h3>
            </div>

           
            <div className="projectsFilter"> 
            
      <ul className="projectsFilterList">
      
                  
      {this.state.filter.map((t, i) => (
          <li>
              <button className={"checked_" + t.checked} id={t.key} onClick={() =>this.addToFilter(t.key)}> 
              {t.name}
              </button>
          </li>
          ))}        
            </ul>
      </div>
            {
              <div className={"root" + ' isHidden' +  this.state.isHidden}>
                <GridList
                  cellHeight={160}
                  className="gridList"
                  cols={4}
                  spacing={3}
                >
                  {this.state.data.map((tile, i) => (
                    <GridListTile
                      key={tile.titleImg[0]}
                      cols={tile.cols}
                      rows={tile.rows || 1}
                    >
                      <Link to={`/project/${tile.id}`}
                        className="linkTo"
                        id={i}
                      ></Link>

                      ({tile.titelVid})?{
                        <img src={process.env.PUBLIC_URL + "/dataset/projects/" + tile.titleImg[0] + ".jpg"} alt={tile.title} />
                 
                        } : {
                          <img src={process.env.PUBLIC_URL + "/dataset/projects/" + tile.titleImg[0] + ".jpg"} alt={tile.title} />
                 
                    }
                      <p>{tile.title}</p>
                    
                    </GridListTile>
                  ))}
                </GridList>
              </div>
            }
          </React.Fragment>
          </div>
        </ScrollableAnchor>
        {this.state.showAllButton == true && <div id="showAllProjects"><button  onClick={() =>this.showMore()}>show more</button></div>}

        
      </section>
    );
  }
}

export default Projects;
