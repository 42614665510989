import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Nav from "./Nav";
import Top from "./Top";
import Intro from "./Intro";
import Projects from "./Projects";
import Contact from "./Contact";
import Skill from "./Skill";
import Download from "./Download";
import Impressum from "./Impressum";
// import Notfound from "./notfound";
import "./_main.scss";
import "./_Top.scss";
import "./_presentation.scss";
//https://davideperozzi.com
//https://infallible-meninsky-3e22da.netlify.com/data/intro.json
//https://app.netlify.com/sites/infallible-meninsky-3e22da/settings/access
//https://codeburst.io/getting-started-with-react-router-5c978f70df91 Routing
//https://medium.com/@Elijah_Meeks/interactive-applications-with-react-d3-f76f7b3ebc71 d3js

class HomeClass extends React.Component {
  constructor() {
    super();

    this.state = {
      scrollPos: window.pageYOffset
    };

    this.getScrollPos = this.getScrollPos.bind(this);
  }

  getScrollPos() {
    return this.state.scrollPos;
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    this.setState({
      scrollPos: scrolled
    });
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  componentWillMount() {
    window.addEventListener("scroll", this.listenToScroll);
  }

  render() {
    return (
      <React.Fragment>
        <Nav scrollPos={this.getScrollPos()}></Nav>
        {/* <div id="Top">Philipp Hafellner</div> */}
        <div id="content">
          <Top scrollPos={this.getScrollPos()}></Top>
          <Intro scrollPos={this.getScrollPos()}></Intro>
          <Projects scrollPos={this.getScrollPos()}></Projects>
          <Skill scrollPos={this.getScrollPos()}></Skill>
          <Contact scrollPos={this.getScrollPos()}></Contact>
          <Download scrollPos={this.getScrollPos()}></Download>
          <Impressum></Impressum>
        </div>
      </React.Fragment>
    );
  }
}

export default HomeClass;
