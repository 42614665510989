import React from "react";
import "./_Nav.scss";

class Nav extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 0
    };
  }

  render() {
    return (
      <nav>
        {/* <span> Scroll Pos {this.props.scrollPos}</span> */}
        <ul>
          <li>
            <a href="#intro" to="/">
              Who<sup>01</sup>
            </a>
          </li>
          <li>
            <a href="#projects" to="/projects">
              Projects<sup>02</sup>
            </a>
          </li>
          <li>
            <a href="#skill" to="/skill">
              Skills<sup>03</sup>
            </a>
          </li>
          <li>
            <a href="#contact" to="/contact">
              Contact<sup>04</sup>
            </a>
          </li>
          <li>
            <a href="#download" to="/download">
              Download<sup>05</sup>
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Nav;
