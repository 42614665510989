const filterset = [


    {
      "key": "top",
      "name": "Top"
    },
    // {
    //   "key": "show",
    //   "name": "Movies, Shows"
    // },
    {
      "key": "reel",
      "name": "Reels"
    },
    {
      "key": "rnd",
      "name": "VFX RnD"
    },
    {
      "key": "game",
      "name": "Realtime, Games, AR"
    },
    {
      "key": "tools",
      "name": "Tools, Scripts, Dev"
    },
    {
      "key": "live",
      "name": "VJing and Live Presentations"
    },
    {
      "key": "manager",
      "name": "Product Management"
    },
    {
      "key": "web",
      "name": "Web-Developement"
    },
    {
      "key": "print",
      "name": "Print"
    },
    {
      "key": "dataviz",
      "name": "Datavisulization and Infographics"
    },
    
    
  ];

export default filterset;