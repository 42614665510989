import React from "react";
import ScrollableAnchor from "react-scrollable-anchor";
//import axios from "axios";
import "./_contact.scss";
import data from "./dataset/contact.json";

class Contact extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      data: data
    };
  }

  // componentWillMount() {
  //   // app.use(function(req, res, next) {
  //   //   res.header("Access-Control-Allow-Origin", "*");
  //   //   res.header("Access-Control-Allow-Headers", "X-Requested-With");
  //   //   next();
  //   //   });

  //   axios

  //     //online http://philipphafellner.com/show/content/items/homeItems.json.js
  //     //https://cors-anywhere.herokuapp.com/https://infallible-meninsky-3e22da.netlify.com/data/contact.json
  //     .get("https://infallible-meninsky-3e22da.netlify.com/data/contact.json", {
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //         "Content-Type": "application/json"
  //       },
  //       withCredentials: false,
  //       responseType: "json"
  //     })
  //     .then(response => {
  //       console.log(response);
  //       this.setState({
  //         data: response.data,
  //         isLoading: false
  //       });
  //     })
  //     .catch(function(error) {
  //       console.log(error);
  //     });
  // }

  render() {
    return (
      <section id="contact">
        <ScrollableAnchor id={"contact"}>
          {!this.state.isLoading ? (
            <React.Fragment>
              <div className="hWrapper">
                <h3>
                  <span>{this.state.data.header}</span>
                </h3>
              </div>
              <div className="content">
                <p>
                  {this.state.data.text}
                  <a href="mailto:hafellner.philipp@gmail.com?subject=Project&body=Hi, my name is ... and I want to contact you regarding ...">
                    <div onMouseOver={this.onToggleOpen}>email</div>
                  </a>
                </p>
                <ul>
                  {this.state.data.links.map((tObj, i) => {
                    return (
                      <li key={i}>
                        <a href={tObj.link}>{tObj.header}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </React.Fragment>
          ) : (
            <span>is loading</span>
          )}
        </ScrollableAnchor>
      </section>
    );
  }
}

export default Contact;
