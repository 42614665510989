import React, { Component } from 'react';
import { BrowserRouter,Switch, Route } from 'react-router-dom';
import Home from './Home';
import Project from './Project';
import Notfound from "./notfound";

class App extends Component {
  render() {
    return (
    <BrowserRouter>
    <>
        <div>
        <Switch>
              <Route exact path='/' component={Home} />
              <Route exact path='/project/:id' component={Project} />
              <Route path="*" component={Notfound} />
        </Switch>
        </div>
        </>
      </BrowserRouter>
    );
  }
}

export default App;