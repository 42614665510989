import React from "react";
//import axios from "axios";
import "./_Top.scss";
import calcPos from "./Calc.js";
import data from "./dataset/intro.json";

class Top extends React.Component {
  constructor() {
    super();

    this.state = {
      data: data,
      isLoading: false
    };
  }

  //componentWillMount() {
  // axios
  //   //online http://philipphafellner.com/show/content/items/homeItems.json.js
  //   //https://cors-anywhere.herokuapp.com/https://infallible-meninsky-3e22da.netlify.com/data/intro.json
  //   .get("https://infallible-meninsky-3e22da.netlify.com/data/intro.json", {
  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Content-Type": "application/json"
  //     },
  //     withCredentials: false,
  //     responseType: "json"
  //   })
  //   .then(response => {
  //     this.setState({
  //       data: response.data,
  //       isLoading: false
  //     });
  //   })
  //   .catch(function(error) {
  //     console.log(error);
  //   });
  //}

  render() {
    return (
      <section id="top">
        {!this.state.isLoading ? (
          <React.Fragment>
            <div className="subtitleWrapper">
              <h2 className="subtitle">{this.state.data.subtitle} </h2><h2 className="contactMe"><a href="#contact" to="/contact">
                Philipp Hafellner</a></h2>
            </div>
            <svg viewBox="0 0 800 250" width="100%" height="auto">
              <text
                className="title"
                x={calcPos(this.props.scrollPos, 0, 0.027, 510, 100)}
                y="130"
              >
                {String(this.state.data.title).split(" ")[0]}
              </text>
              <text
                className="title"
                x={calcPos(this.props.scrollPos, 0.01, 0.028, 250, 0)}
                y="220"
              >
                {String(this.state.data.title).split(" ")[1]}
              </text>
              <rect
                x="460"
                y="140"
                width="800"
                height="500"
                fill="white"
              ></rect>
              {/* <div className="wrapper"><p style={{ left:  (calcPos(this.props.scrollPos, 0,0.03,50,20))+ 'vw' }}>{String</p></div> */}
              {/* <div className="wrapper"><p style={{ left:  (calcPos(this.props.scrollPos, 0,0.03,46,0))+ 'vw' }}>{String(this.state.data.title).split(" ")[1]}</p></div> </h1>*/}
            </svg>
          </React.Fragment>
        ) : (
          <h1 className="title">
            <p>is</p>
            <p>loading</p>
          </h1>
        )}
      </section>
    );
  }
}
export default Top;
